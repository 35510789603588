<template>
  <el-row class="firstRow">
    <el-col>
      <el-card class="box-card">
        <template #header>
          <div class="card-header">
            <span class="SubHead">会员信息</span>
            <el-button class="button" type="text" @click="showRule">积分规则</el-button>
          </div>
        </template>
        <el-row>
          <el-col :span="6">
            <div class="menInfo">
              <div class="userInfo">
                <el-avatar :src="userInfo.w_headimgurl" fit="fill"></el-avatar>
                <div>
                  <p class="Text">{{ userInfo.name }}</p>
                  <el-tag size="small" v-show="points < 2000"><i class="iconfont icon-anchor-full"></i> V1 水手</el-tag>
                  <el-tag size="small" v-show="points >= 2000 && points < 5000"
                    ><i class="iconfont icon-anchor-full"></i> V2 三副</el-tag
                  >
                  <el-tag size="small" v-show="points >= 5000 && points < 20000"
                    ><i class="iconfont icon-anchor-full"></i> V3 二副</el-tag
                  >
                  <el-tag size="small" v-show="points >= 20000 && points < 50000"
                    ><i class="iconfont icon-anchor-full"></i> V4 大副</el-tag
                  >
                  <el-tag size="small" v-show="points >= 50000"
                    ><i class="iconfont icon-anchor-full"></i> V5 船长</el-tag
                  >
                </div>
              </div>
              <p class="score">
                您的积分：<span class="brandColor">{{ points }}</span>
              </p>
              <p class="day">统计截止：{{ cutdownTime }}</p>
            </div>
          </el-col>
          <el-col :span="18">
            <div class="vipBar">
              <div class="barStart">
                <span>{{ rank.rank_integral }}</span>
                <span class="grade">{{ rank.rank }}</span>
              </div>
              <div class="progress">
                <div class="currentProgress" :style="{ right: `${100 - progress -1}%` }">
                  <span class="Text" style="display: block">{{ points }}</span>
                  <img src="../../assets/crown.png" alt="" class="crown" />
                </div>
                <el-progress :percentage="progress" :show-text="false" />
              </div>
              <div class="barEnd">
                <span>{{ rank.new_rank_integral }}</span>
                <span class="grade">{{ rank.new_rank }}</span>
              </div>
            </div>

            <ul class="privilege">
              <li>
                <div class="vipItem">
                  <i class="iconfont icon-kecheng" :class="points >= 0 ? 'abledColor' : ''"></i>
                  <div class="unlock" v-show="points >= 0">
                    <i class="el-icon-unlock"></i>
                    <span>已解锁</span>
                  </div>
                </div>
                <p :class="points >= 0 ? 'abledColor' : ''">付费课程</p>
              </li>
              <li>
                <div class="vipItem">
                  <i class="iconfont icon-guanliyuan" :class="points >= 2000 ? 'abledColor' : ''"></i>
                  <div class="lock" v-if="points < 2000">
                    <i class="el-icon-lock"></i>
                    <span>v2解锁</span>
                  </div>
                  <div class="unlock" v-else>
                    <i class="el-icon-unlock"></i>
                    <span>已解锁</span>
                  </div>
                </div>
                <p :class="points >= 2000 ? 'abledColor' : ''">专属管家</p>
              </li>
              <li>
                <div class="vipItem">
                  <i class="iconfont icon-a-ziyuan103" :class="points >= 2000 ? 'abledColor' : ''"></i>
                  <div class="lock" v-if="points < 2000">
                    <i class="el-icon-lock"></i>
                    <span>v2解锁</span>
                  </div>
                  <div class="unlock" v-else>
                    <i class="el-icon-unlock"></i>
                    <span>已解锁</span>
                  </div>
                </div>
                <p :class="points >= 2000 ? 'abledColor' : ''">邀请特权</p>
              </li>
              <li>
                <div class="vipItem">
                  <i class="iconfont icon-jichanglvsetongdao" :class="points >= 5000 ? 'abledColor' : ''"></i>
                  <div class="lock" v-if="points < 5000">
                    <i class="el-icon-lock"></i>
                    <span>v3解锁</span>
                  </div>
                  <div class="unlock" v-else>
                    <i class="el-icon-unlock"></i>
                    <span>已解锁</span>
                  </div>
                </div>
                <p :class="points >= 5000 ? 'abledColor' : ''">问题解决绿色通道</p>
              </li>
              <li>
                <div class="vipItem">
                  <i class="iconfont icon-buke" :class="points >= 5000 ? 'abledColor' : ''"></i>
                  <div class="lock" v-if="points < 5000">
                    <i class="el-icon-lock"></i>
                    <span>v3解锁</span>
                  </div>
                  <div class="unlock" v-else>
                    <i class="el-icon-unlock"></i>
                    <span>已解锁</span>
                  </div>
                </div>
                <p :class="points >= 5000 ? 'abledColor' : ''">闭门课程</p>
              </li>
              <li>
                <div class="vipItem">
                  <i class="iconfont icon-zhongdianguanggaowei" :class="points >= 5000 ? 'abledColor' : ''"></i>
                  <div class="lock" v-if="points < 5000">
                    <i class="el-icon-lock"></i>
                    <span>v3解锁</span>
                  </div>
                  <div class="unlock" v-else>
                    <i class="el-icon-unlock"></i>
                    <span>已解锁</span>
                  </div>
                </div>
                <p :class="points >= 5000 ? 'abledColor' : ''">广告数据复盘</p>
              </li>
              <li>
                <div class="vipItem">
                  <i class="iconfont icon-ylzhanghuchongzhi" :class="points >= 5000 ? 'abledColor' : ''"></i>
                  <div class="lock" v-if="points < 5000">
                    <i class="el-icon-lock"></i>
                    <span>v3解锁</span>
                  </div>
                  <div class="unlock" v-else>
                    <i class="el-icon-unlock"></i>
                    <span>已解锁</span>
                  </div>
                </div>
                <p :class="points >= 5000 ? 'abledColor' : ''">账户充值</p>
              </li>
              <li>
                <div class="vipItem">
                  <i class="iconfont icon-sucai" :class="points >= 20000 ? 'abledColor' : ''"></i>
                  <div class="lock" v-if="points < 20000">
                    <i class="el-icon-lock"></i>
                    <span>v4解锁</span>
                  </div>
                  <div class="unlock" v-else>
                    <i class="el-icon-unlock"></i>
                    <span>已解锁</span>
                  </div>
                </div>
                <p :class="points >= 20000 ? 'abledColor' : ''">免费素材设计</p>
              </li>
              <li>
                <div class="vipItem">
                  <i class="iconfont icon-guanliyuan1" :class="points >= 20000 ? 'abledColor' : ''"></i>
                  <div class="lock" v-if="points < 20000">
                    <i class="el-icon-lock"></i>
                    <span>v4解锁</span>
                  </div>
                  <div class="unlock" v-else>
                    <i class="el-icon-unlock"></i>
                    <span>已解锁</span>
                  </div>
                </div>
                <p :class="points >= 20000 ? 'abledColor' : ''">优先推荐直客经理</p>
              </li>
              <li>
                <div class="vipItem">
                  <i class="iconfont icon-celve-yonghucelve" :class="points >= 50000 ? 'abledColor' : ''"></i>
                  <div class="lock" v-if="points < 50000">
                    <i class="el-icon-lock"></i>
                    <span>v5解锁</span>
                  </div>
                  <div class="unlock" v-else>
                    <i class="el-icon-unlock"></i>
                    <span>已解锁</span>
                  </div>
                </div>
                <p :class="points >= 50000 ? 'abledColor' : ''">全渠道营销策略</p>
              </li>
            </ul>
          </el-col>
        </el-row>
        <el-alert :title="alertMsg" type="warning" show-icon v-show="alertMsg != ''" @close="closeAlert" />
      </el-card>
    </el-col>
  </el-row>

  <el-row class="secondRow">
    <el-col>
      <el-card class="box-card">
        <template #header>
          <div class="card-header">
            <span class="SubHead">积分分类</span>
          </div>
        </template>
        <el-row>
          <el-col :span="6" class="exampleCol">
            <div class="example">
              <div class="example1"></div>
              <span class="Text">我的积分</span>
            </div>
            <div class="example">
              <div class="example2"></div>
              <span class="Text">下一等级平均积分</span>
            </div>
          </el-col>
          <el-col :span="6">
            <el-progress type="dashboard" :percentage="base_percent">
              <template #default="{ percentage }">
                <span class="percentage-label">基础积分</span>
                <span class="percentage-value">{{ percentage }}%</span>
              </template>
            </el-progress>
          </el-col>
          <el-col :span="6">
            <el-progress type="dashboard" :percentage="study_percent">
              <template #default="{ percentage }">
                <span class="percentage-label">学习积分</span>
                <span class="percentage-value">{{ percentage }}%</span>
              </template>
            </el-progress>
          </el-col>
          <el-col :span="6">
            <el-progress type="dashboard" :percentage="business_percent">
              <template #default="{ percentage }">
                <span class="percentage-label">业务积分</span>
                <span class="percentage-value">{{ percentage }}%</span>
              </template>
            </el-progress>
          </el-col>
        </el-row>
      </el-card>
    </el-col>
  </el-row>

  <!-- 积分明细 -->
  <el-row>
    <el-col>
      <el-card class="box-card">
        <template #header>
          <div class="card-header">
            <span class="SubHead">积分明细</span>
          </div>
        </template>
        <el-form ref="pointFormRef" :inline="true" :model="pointForm" style="padding-top: 15px">
          <el-row justify="space-between">
            <el-col :span="20">
              <el-form-item label="选择日期" prop="timeRange">
                <el-date-picker
                  v-model="pointForm.timeRange"
                  type="daterange"
                  size="small"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  value-format="X"
                  style="margin-left: 14px"
                ></el-date-picker>
              </el-form-item>
              <el-form-item label="分类" prop="type">
                <el-select v-model="pointForm.type" placeholder="请选择" size="small">
                  <el-option label="基础积分" value="1"></el-option>
                  <el-option label="学习积分" value="2"></el-option>
                  <el-option label="业务积分" value="3"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item style="margin-left: 14px">
                <el-button type="primary" @click="queryFormBtn" size="small">查询</el-button>
                <el-button @click="resetFormBtn" size="small">重置</el-button>
              </el-form-item>
            </el-col>
            <el-col :span="4" style="text-align: right">
              <el-button type="text" @click="downLoadData">下载</el-button>
            </el-col>
          </el-row>
        </el-form>

        <el-table
          ref="pointRef"
          :data="pointList"
          :row-key="getPointId"
          class="pointTable"
          style="width: 100%"
          :header-cell-style="{
            background: 'var(--el-table-row-hover-background-color)'
          }"
        >
          <el-table-column label="描述" width="400px">
            <template #default="{row}">
              <span v-html="row.msg"></span>
            </template>
          </el-table-column>
          <el-table-column property="integral" label="积分">
            <template #default="{row}">
              <span v-if="row.integral_type == 1" class="brandColor">+{{ row.integral }}</span>
              <span v-else class="dangerColor">-{{ row.integral }}</span>
            </template>
          </el-table-column>
          <el-table-column label="分类">
            <template #default="{row}">
              <span v-if="row.category == 1">基础积分</span>
              <span v-else-if="row.category == 2">学习积分</span>
              <span v-else>业务积分</span>
            </template>
          </el-table-column>
          <el-table-column property="create_at" sortable label="积分获取时间" />
          <el-table-column property="expired_at" sortable label="积分到期时间" />
          <template #empty>
            <img src="@/assets/empty.gif" alt="" style="width: 400px; height: 400px" />
          </template>
        </el-table>
        <el-row>
          <!-- 分页器 -->
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="queryInfo.page"
            :page-sizes="[5, 10]"
            :page-size="queryInfo.pagesize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
            background
          >
          </el-pagination>
        </el-row>
      </el-card>
    </el-col>
  </el-row>

  <!-- 积分规则对话框 -->
  <el-dialog v-model="ruleDialogVisible" title="积分规则" width="1000px">
    <!-- 内容主体区域 -->
    <table border="1" width="100%" height="auto" align="center" cellspacing="0" cellpadding="20" class="pointTable">
      <tr v-for="(t, index) in ruleExplain" :key="index">
        <td>{{ t.type }}</td>
        <td>{{ t.way }}</td>
        <td>{{ t.code }}</td>
        <td>{{ t.intro }}</td>
      </tr>
    </table>

    <template #footer>
      <span class="dialog-footer">
        <el-button size="small" type="primary" @click="ruleDialogVisible = false">我已知晓</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import base from "../../request/base";
export default {
  name: 'MemberUpgrade',
  data() {
    return {
      base: base,
      userInfo: '',
      points: 0,
      cutdownTime: '',
      rank: {},
      progress: 0,
      base_percent: 0,
      study_percent: 0,
      business_percent: 0,
      alertContent: 'xx',
      // 表单查询
      queryInfo: {
        query: '',
        page: 1,
        pagesize: 5
      },
      total: 0,
      pointForm: {
        timeRange: '',
        type: ''
      },
      pointList: [],
      ruleDialogVisible: false,
      alertMsg: '',
      ruleExplain: [
        {
          type: '分类',
          way: '赚取方式',
          code: '具体得分',
          intro: '简要说明'
        },
        {
          type: '基础积分',
          way: '设置用户名',
          code: '10',
          intro: '成功设置用户名，仅奖励一次'
        },
        {
          type: '',
          way: '设置密码',
          code: '10',
          intro: '成功设置密码，仅奖励一次'
        },
        {
          type: '',
          way: '订阅广审提醒',
          code: '10',
          intro: '成功订阅广审提醒，仅奖励一次'
        },
        {
          type: '',
          way: '查看帮助中心',
          code: '10',
          intro: '前往帮助中心，查看一篇文章，仅奖励一次'
        },
        {
          type: '',
          way: '使用YKnow',
          code: '10',
          intro: '前往YKnow，点击一次菜单，仅奖励一次'
        },
        {
          type: '',
          way: '前往周5出海',
          code: '10',
          intro: '前往周5出海，浏览某个详情页，仅奖励一次'
        },
        {
          type: '',
          way: '每日登录',
          code: '1',
          intro: '每日登录YinoCloud，默认积分'
        },
        {
          type: '',
          way: '完成问卷',
          code: '50',
          intro: '完成每次问卷，获得一次，默认积分'
        },
        {
          type: '',
          way: '帮助中心反馈',
          code: '10',
          intro: '完成一次帮助文档评星，选择无帮助至少有5个字的内容，获得一次。单次文档只能获得一次积分'
        },
        {
          type: '',
          way: 'YKnow反馈',
          code: '10',
          intro: '完成一次YKnow点评，获得一次。每日上限10'
        },
        {
          type: '',
          way: '产品反馈',
          code: '50',
          intro: '完成一次产品反馈并被采纳（回复），获得一次'
        },
        {
          type: '学习任务',
          way: '报名活动',
          code: '10',
          intro: '报名参加直播/活动/课程等，每日上限50'
        },
        {
          type: '',
          way: '活动签到',
          code: '10',
          intro: '活动签到，获得一次，默认积分。单次活动只能获得一次积分'
        },
        {
          type: '',
          way: '活动评价',
          code: '10',
          intro: '完成一次直播/课程评星，并至少有5个字的内容，获得一次。单次活动只能获得一次积分'
        },
        {
          type: '业务积分',
          way: '广告消耗',
          code: '等比',
          intro: '每天消耗50美金=1分，保留1位小数，默认积分'
        }
      ]
    }
  },
  mounted() {
    this.userInfo = this.$store.getters.userInfo
    this.getPointList()
  },
  methods: {
    async getPointList() {
      const { data: res } = await this.$http.post('user/integral_list', {
        page: this.queryInfo.page,
        pagesize: this.queryInfo.pagesize,
        timeRange: this.pointForm.timeRange,
        type: this.pointForm.type
      })
      if (res.code == 300) {
        this.$router.push('/login')
      }
      this.points = res.data.integral
      this.cutdownTime = res.data.date
      this.rank = res.data.rank
      this.progress = res.data.rank.bai
      this.base_percent = res.data.base_percent
      this.study_percent = res.data.study_percent
      this.business_percent = res.data.business_percent
      this.pointList = res.list
      this.total = res.data.total
      this.alertMsg = res.data.message
    },
    async closeAlert() {
      const { data: res } = await this.$http.post('user/set_integral_message')
    },
    queryFormBtn() {
      this.getPointList()
    },
    resetFormBtn() {
      this.$refs.pointFormRef.resetFields()
      this.getPointList()
    },
    getPointId(point) {
      return point.id
    },
    // 展示条数改变触发的函数
    handleSizeChange(newSize) {
      this.queryInfo.pagesize = newSize
      this.getPointList()
    },
    handleCurrentChange(newPage) {
      this.queryInfo.page = newPage
      this.getPointList()
    },
    downLoadData() {
      if (this.pointList == '') {
        return this.$message({
          showClose: true,
          message: '暂无数据',
          type: 'warning'
        })
      }
      window.location.href = `${this.base.admin_url}/cloud/user/integral_export?token=${this.userInfo.token}&timeRange=${this.pointForm.timeRange}&type=${this.pointForm.type}`
    },
    showRule() {
      this.ruleDialogVisible = true
    }
  }
}
</script>

<style lang="less" scoped>
.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.percentage-value {
  display: block;
  margin-top: 10px;
  font-size: 28px;
}
.percentage-label {
  display: block;
  margin-top: 10px;
  font-size: 12px;
}
a {
  text-decoration: none;
}
:deep(.el-table__row a) {
  text-decoration: none;
  color: #2b82ff;
}
.firstRow,
.secondRow,
.thirdRow {
  margin-bottom: 16px;
}
.menInfo {
  width: 100%;
  height: auto;
  padding: 15px;
  box-sizing: border-box;
  .userInfo {
    display: flex;
    justify-content: center;
    align-items: center;
    p {
      margin: 0 0 10px 0;
    }
    .el-avatar {
      width: 60px;
      height: 60px;
      margin-right: 8px;
    }
  }
  p.score,
  p.day {
    font-size: 14px;
    text-align: center;
  }
  p.day {
    color: #8c8c8c;
    margin-bottom: 0;
  }
}
.vipBar {
  display: flex;
  align-items: center;
  margin: 30px 0;
  .barStart,
  .barEnd {
    width: 10%;
    text-align: center;
    span {
      display: block;
      font-size: 14px;
    }
    .grade {
      font-size: 14px;
      color: #2b82ff;
    }
  }
  .progress {
    position: relative;
    top: 10px;
    // width: 80%;
    width: 100%;
    padding: 0 16px;
    .currentProgress {
      position: absolute;
      bottom: 15px;
      text-align: center;
      .crown {
        width: 20px;
        height: 20px;
      }
    }
  }
}
.privilege {
  display: flex;
  flex-wrap: wrap;
  li {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 10%;
    .iconfont {
      font-size: 35px;
      color: #bfbfbf;
    }
    p {
      font-size: 12px;
      color: #8c8c8c;
      text-align: center;
      margin: 10px;
      span {
        color: #8c8c8c;
      }
    }
  }
  .vipItem {
    position: relative;
    .lock,
    .unlock {
      width: 60px;
      position: absolute;
      left: -15px;
      bottom: -5px;
      z-index: 1;
      text-align: center;
      background-color: #f5f5f5;
      border-radius: 4px;
      padding: 0 3px;

      span,
      i {
        font-size: 12px;
        color: #595959;
      }
    }
    .unlock {
      background-color: #ecf5ff;
      span,
      i {
        color: #409eff;
      }
    }
  }
}
.exampleCol {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.example {
  display: flex;
  align-items: center;
  .example1,
  .example2 {
    display: inline-block;
    width: 20px;
    height: 20px;
    background-color: #2b82ff;
    margin-right: 10px;
  }
  .example2 {
    background-color: #e5e5e5;
  }
}
.pointTable {
  border-color: #dcdfe6;
  tr {
    td {
      width: 15%;
      padding: 10px;
    }
    td:last-child {
      width: 55%;
    }
  }
}
.abledColor {
  color: #2b82ff !important;
}
.icon-anchor-full {
  font-size: 14px;
}
</style>
