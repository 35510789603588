<template>
  <!-- 会员信息 -->
  <el-row class="firstRow">
    <el-col>
      <el-card class="box-card">
        <template #header>
          <div class="card-header">
            <span class="SubHead">会员成长体系</span>
            <router-link to="/upgrade"><span class="LinkText">积分明细</span></router-link>
          </div>
        </template>
        <el-row>
          <el-col :span="6">
            <div class="menInfo">
              <div class="userInfo">
                <el-avatar :src="userInfo.w_headimgurl" fit="fill"></el-avatar>
                <div>
                  <p class="Text" style="font-size: 16px">{{ userInfo.name }}</p>
                  <p style="color: #262626">
                    您的积分：<span class="brandColor">{{ points }}</span>
                  </p>
                  <el-tag size="small" v-show="points < 2000"><i class="iconfont icon-anchor-full"></i> V1 水手</el-tag>
                  <el-tag size="small" v-show="points >= 2000 && points < 5000"
                    ><i class="iconfont icon-anchor-full"></i> V2 三副</el-tag
                  >
                  <el-tag size="small" v-show="points >= 5000 && points < 20000"
                    ><i class="iconfont icon-anchor-full"></i> V3 二副</el-tag
                  >
                  <el-tag size="small" v-show="points >= 20000 && points < 50000"
                    ><i class="iconfont icon-anchor-full"></i> V4 大副</el-tag
                  >
                  <el-tag size="small" v-show="points >= 50000"
                    ><i class="iconfont icon-anchor-full"></i> V5 船长</el-tag
                  >
                </div>
              </div>
              <p class="HelpText score">
                距离升级还需要积分 <span>{{ upgrade_points }}</span>
              </p>
              <p class="day" v-show="expired_points > 0">
                你有积分 <span>{{ expired_points }}</span> 在 <span class="dangerColor">{{ expired_time }}</span> 天过期
              </p>
            </div>
          </el-col>
          <el-col :span="18">
            <div class="growth">
              <ul>
                <li class="progress-lv1">
                  <div>
                    <span class="progress-num">0</span>
                    <span class="progress-zhu"></span>
                    <span class="progress-icon">V1 水手</span>
                  </div>
                  <div class="user-lv" v-show="points < 2000">
                    <img src="../../assets/crown.png" alt="" class="crown" />
                    <el-avatar :src="userInfo.w_headimgurl" fit="fill" width="36" height="36"></el-avatar>
                  </div>
                </li>
                <li class="progress-lv2">
                  <div>
                    <span class="progress-num">2000</span>
                    <span class="progress-zhu" :class="points >= 2000 ? 'reach' : ''"></span>
                    <span class="progress-icon">V2 三副</span>
                  </div>
                  <div class="user-lv" v-show="points >= 2000 && points < 5000">
                    <img src="../../assets/crown.png" alt="" class="crown" />
                    <el-avatar :src="userInfo.w_headimgurl" fit="fill" width="36" height="36"></el-avatar>
                  </div>
                </li>
                <li class="progress-lv3">
                  <div>
                    <span class="progress-num">5000</span>
                    <span class="progress-zhu" :class="points >= 5000 ? 'reach' : ''"></span>
                    <span class="progress-icon">V3 二副</span>
                  </div>
                  <div class="user-lv" v-show="points >= 5000 && points < 20000">
                    <img src="../../assets/crown.png" alt="" class="crown" />
                    <el-avatar :src="userInfo.w_headimgurl" fit="fill" width="36" height="36"></el-avatar>
                  </div>
                </li>
                <li class="progress-lv4">
                  <div>
                    <span class="progress-num">20000</span>
                    <span class="progress-zhu" :class="points >= 20000 ? 'reach' : ''"></span>
                    <span class="progress-icon">V4 大副</span>
                  </div>
                  <div class="user-lv" v-show="points >= 20000 && points < 50000">
                    <img src="../../assets/crown.png" alt="" class="crown" />
                    <el-avatar :src="userInfo.w_headimgurl" fit="fill" width="36" height="36"></el-avatar>
                  </div>
                </li>
                <li class="progress-lv5">
                  <div>
                    <span class="progress-num">50000</span>
                    <span class="progress-zhu" :class="points >= 50000 ? 'reach' : ''"></span>
                    <span class="progress-icon">V5 船长</span>
                  </div>
                  <div class="user-lv" v-show="points >= 50000">
                    <img src="../../assets/crown.png" alt="" class="crown" />
                    <el-avatar :src="userInfo.w_headimgurl" fit="fill" width="36" height="36"></el-avatar>
                  </div>
                </li>
              </ul>
              <div class="progress-line"></div>
            </div>
          </el-col>
        </el-row>
      </el-card>
    </el-col>
  </el-row>

  <el-row class="secondRow">
    <el-col>
      <el-card class="box-card">
        <template #header>
          <div class="card-header">
            <span class="SubHead">会员特权</span>
            <!-- <el-button class="button" type="text">操作</el-button> -->
          </div>
        </template>
        <ul class="privilege">
          <li>
            <div class="vipItem">
              <i class="iconfont icon-ylfufeikecheng" :class="points >= 0 ? 'abledColor' : ''"></i>
              <div class="unlock" v-show="points >= 0">
                <i class="el-icon-unlock"></i>
                <span>已解锁</span>
              </div>
            </div>
            <p v-if="points < 2000" :class="points >= 0 ? 'abledColor' : ''">付费课程<br /><span>1人次/季度</span></p>
            <p v-else :class="points >= 0 ? 'abledColor' : ''">付费课程<br /><span>3人次/季度</span></p>
          </li>
          <li>
            <div class="vipItem">
              <i class="iconfont icon-ylzhuanshuguanjia" :class="points >= 2000 ? 'abledColor' : ''"></i>
              <div class="lock" v-if="points < 2000">
                <i class="el-icon-lock"></i>
                <span>v2解锁</span>
              </div>
              <div class="unlock" v-else>
                <i class="el-icon-unlock"></i>
                <span>已解锁</span>
              </div>
              <el-tooltip placement="top" effect="light">
                <template #content>
                  <span>点击申请</span>
                </template>
                <i
                  class="el-icon-warning-outline applyIcon"
                  :class="member_manager == 1 ? 'applied' : ''"
                  v-show="points >= 2000"
                  @click="applyDesign(2)"
                ></i>
              </el-tooltip>
            </div>
            <p :class="points >= 2000 ? 'abledColor' : ''">专属管家</p>
          </li>
          <li>
            <div class="vipItem">
              <i class="iconfont icon-ylyaoqingtequan" :class="points >= 2000 ? 'abledColor' : ''"></i>
              <div class="lock" v-if="points < 2000">
                <i class="el-icon-lock"></i>
                <span>v2解锁</span>
              </div>
              <div class="unlock" v-else>
                <i class="el-icon-unlock"></i>
                <span>已解锁</span>
              </div>
            </div>
            <p :class="points >= 2000 ? 'abledColor' : ''" style="margin-bottom: 0">邀请特权</p>
            <router-link to="/invite"><span class="toInvite" v-if="points >= 2000">点击查看详情</span></router-link>
          </li>
          <li>
            <div class="vipItem">
              <i class="iconfont icon-yllvsetongdao" :class="points >= 5000 ? 'abledColor' : ''"></i>
              <div class="lock" v-if="points < 5000">
                <i class="el-icon-lock"></i>
                <span>v3解锁</span>
              </div>
              <div class="unlock" v-else>
                <i class="el-icon-unlock"></i>
                <span>已解锁</span>
              </div>
            </div>
            <p :class="points >= 5000 ? 'abledColor' : ''">问题解决绿色通道</p>
          </li>
          <li>
            <div class="vipItem">
              <i class="iconfont icon-ylbimenkecheng" :class="points >= 5000 ? 'abledColor' : ''"></i>
              <div class="lock" v-if="points < 5000">
                <i class="el-icon-lock"></i>
                <span>v3解锁</span>
              </div>
              <div class="unlock" v-else>
                <i class="el-icon-unlock"></i>
                <span>已解锁</span>
              </div>
              <el-tooltip placement="top" effect="light">
                <template #content>
                  <span>点击申请</span>
                </template>
                <i
                  class="el-icon-warning-outline applyIcon"
                  :class="member_course == 1 ? 'applied' : ''"
                  v-show="points >= 5000"
                  @click="applyDesign(3)"
                ></i>
              </el-tooltip>
            </div>
            <p :class="points >= 5000 ? 'abledColor' : ''">闭门课程</p>
          </li>
          <li>
            <div class="vipItem">
              <i class="iconfont icon-ylguanggaoshuju" :class="points >= 5000 ? 'abledColor' : ''"></i>
              <div class="lock" v-if="points < 5000">
                <i class="el-icon-lock"></i>
                <span>v3解锁</span>
              </div>
              <div class="unlock" v-else>
                <i class="el-icon-unlock"></i>
                <span>已解锁</span>
              </div>
              <el-tooltip placement="top" effect="light">
                <template #content>
                  <span>点击申请</span>
                </template>
                <i
                  class="el-icon-warning-outline applyIcon"
                  :class="member_review == 1 ? 'applied' : ''"
                  v-show="points >= 5000"
                  @click="applyDesign(4)"
                ></i>
              </el-tooltip>
            </div>
            <p v-if="points < 20000" :class="points >= 5000 ? 'abledColor' : ''">
              广告数据复盘<br /><span>1次/季度</span>
            </p>
            <p v-else :class="points >= 5000 ? 'abledColor' : ''">广告数据复盘<br /><span>3次/季度</span></p>
          </li>
          <li>
            <div class="vipItem">
              <i class="iconfont icon-ylzhanghuchongzhi" :class="points >= 5000 ? 'abledColor' : ''"></i>
              <div class="lock" v-if="points < 5000">
                <i class="el-icon-lock"></i>
                <span>v3解锁</span>
              </div>
              <div class="unlock" v-else>
                <i class="el-icon-unlock"></i>
                <span>已解锁</span>
              </div>
            </div>
            <p v-if="points < 50000" :class="points >= 5000 ? 'abledColor' : ''">
              账户充值<br /><span>单日上限3w</span>
            </p>
            <p v-else :class="points >= 50000 ? 'abledColor' : ''">账户充值<br /><span>单日上限5w</span></p>
          </li>
          <li>
            <div class="vipItem">
              <i class="iconfont icon-ylsucaisheji" :class="points >= 20000 ? 'abledColor' : ''"></i>
              <div class="lock" v-if="points < 20000">
                <i class="el-icon-lock"></i>
                <span>v4解锁</span>
              </div>
              <div class="unlock" v-else>
                <i class="el-icon-unlock"></i>
                <span>已解锁</span>
              </div>

              <el-tooltip placement="top" effect="light">
                <template #content>
                  <span>点击前往素材工单页面</span>
                </template>
                <!-- <i
                  class="el-icon-warning-outline applyIcon"
                  :class="member_benefits == 1 ? 'applied' : ''"
                  v-show="points >= 20000"
                  @click="applyDesign(1)"
                ></i> -->
                <i class="el-icon-warning-outline applyIcon" v-show="points >= 20000" @click="goFodderWork"></i>
              </el-tooltip>
            </div>
            <p v-if="points < 50000" :class="points >= 20000 ? 'abledColor' : ''">
              免费素材设计<br /><span>1批/季度</span>
            </p>
            <p v-else :class="points >= 20000 ? 'abledColor' : ''">免费素材设计<br /><span>3批/季度</span></p>
          </li>
          <li>
            <div class="vipItem">
              <i class="iconfont icon-ylzhikejingli" :class="points >= 20000 ? 'abledColor' : ''"></i>
              <div class="lock" v-if="points < 20000">
                <i class="el-icon-lock"></i>
                <span>v4解锁</span>
              </div>
              <div class="unlock" v-else>
                <i class="el-icon-unlock"></i>
                <span>已解锁</span>
              </div>
            </div>
            <p :class="points >= 20000 ? 'abledColor' : ''">优先推荐直客经理</p>
          </li>
          <li>
            <div class="vipItem">
              <i class="iconfont icon-ylyingxiaocelve" :class="points >= 50000 ? 'abledColor' : ''"></i>
              <div class="lock" v-if="points < 50000">
                <i class="el-icon-lock"></i>
                <span>v5解锁</span>
              </div>
              <div class="unlock" v-else>
                <i class="el-icon-unlock"></i>
                <span>已解锁</span>
              </div>
              <el-tooltip placement="top" effect="light">
                <template #content>
                  <span>点击申请</span>
                </template>
                <i
                  class="el-icon-warning-outline applyIcon"
                  :class="member_strategy == 1 ? 'applied' : ''"
                  v-show="points >= 50000"
                  @click="applyDesign(5)"
                ></i>
              </el-tooltip>
            </div>
            <p :class="points >= 50000 ? 'abledColor' : ''">全渠道营销策略</p>
          </li>
        </ul>
      </el-card>
    </el-col>
  </el-row>

  <el-row class="thirdRow">
    <el-col>
      <el-card class="box-card">
        <template #header>
          <div class="card-header">
            <span class="SubHead"
              >成长任务<i v-show="task_type == 1" class="taskTips"
                >(企业认证通过，并且完成页面右上角的新手任务后，再来完成成长任务)</i
              ></span
            >
          </div>
        </template>
        <ul class="task">
          <li v-for="(t, index) in task" :key="index">
            <el-row>
              <el-col :span="3" class="firstCol">
                <i
                  class="el-icon-refresh-right warningColor"
                  style="font-size: 50px"
                  :class="task_type == 1 ? 'disabledColor' : ''"
                  v-if="t.type == 1"
                ></i>
                <i
                  class="el-icon-finished successColor"
                  style="font-size: 50px"
                  :class="task_type == 1 ? 'disabledColor' : ''"
                  v-else
                ></i>
              </el-col>
              <el-col :span="16">
                <h3 :class="task_type == 1 ? 'disabledColor' : ''">
                  {{ t.name }}<span v-show="t.num >= 0">（{{ t.num }}/5）</span>
                </h3>
                <p :class="task_type == 1 ? 'disabledColor' : ''">{{ t.msg }}</p>
              </el-col>
              <el-col :span="5" class="lastCol">
                <el-button
                  size="small"
                  type="primary"
                  v-if="t.type == 1"
                  :disabled="task_type == 1"
                  @click="goFinish(t.url)"
                  >去完成</el-button
                >
                <el-button size="small" disabled v-else>已完成</el-button>
              </el-col>
            </el-row>
          </li>
        </ul>
      </el-card>
    </el-col>
  </el-row>
</template>

<script>
export default {
  name: 'MemberInfo',
  data() {
    return {
      userInfo: '',
      points: 0,
      upgrade_points: 0,
      expired_points: 0,
      expired_time: 0,
      task: [],
      task_type: '',
      member_manager: 0,
      member_course: 0,
      member_review: 0,
      member_benefits: 0,
      member_strategy: 0,
    }
  },
  mounted() {
    this.userInfo = this.$store.getters.userInfo
    this.getMemberInfo()
  },
  methods: {
    async getMemberInfo() {
      const { data: res } = await this.$http.post('user/my_member')
      if (res.code == 300) {
        this.$router.push('/login')
      }
      this.points = res.data.integral
      this.upgrade_points = res.data.upgrade_integral
      this.expired_points = res.data.expired_integral
      this.expired_time = res.data.expired_time
      this.task = res.data.task
      this.task_type = res.data.task_type
      this.member_benefits = res.data.member_benefits.type1
      this.member_manager = res.data.member_benefits.type2
      this.member_course = res.data.member_benefits.type3
      this.member_review = res.data.member_benefits.type4
      this.member_strategy = res.data.member_benefits.type5
    },
    goFinish(url) {
      if (url != '') {
        window.open(url)
      }
    },
    async applyDesign(type) {
      if (this.member_manager == 1 && type == 2) {
        return this.$message.warning('已申请，请勿重复申请')
      }
      if (this.member_course == 1 && type == 3) {
        return this.$message.warning('已申请，请勿重复申请')
      }
      if (this.member_review == 1 && type == 4) {
        return this.$message.warning('已申请，请勿重复申请')
      }
      if (this.member_benefits == 1 && type == 1) {
        return this.$message.warning('已申请，请勿重复申请')
      }
      if (this.member_strategy == 1 && type == 5) {
        return this.$message.warning('已申请，请勿重复申请')
      }
      // 再次确认是否充值
      const confirmResult = await this.$confirm(`确定申请吗?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).catch((err) => err)
      if (confirmResult !== 'confirm') {
        return
      } else {
        const { data: res } = await this.$http.post('user/member_benefits', {
          type: type,
        })
        if (res.code == 200) {
          this.getMemberInfo()
        }
      }
    },
    goFodderWork() {
      this.$router.push('/fodderWork')
    },
  },
}
</script>

<style lang="less" scoped>
.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
a {
  text-decoration: none;
}
.firstRow,
.secondRow,
.thirdRow {
  margin-bottom: 16px;
}
.menInfo {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 100%;
  height: 180px;
  border: 1px dashed #2b82ff;
  padding: 15px;
  box-sizing: border-box;
  .userInfo {
    display: flex;
    justify-content: center;
    align-items: center;
    p {
      margin: 0 0 10px 0;
    }
    .el-avatar {
      width: 60px;
      height: 60px;
      margin-right: 8px;
    }
  }
  p.score,
  p.day {
    font-size: 14px;
    text-align: center;
    margin: 0;
  }
  p.day {
    color: #262626;
    margin-bottom: 0;
  }
}
.growth {
  position: relative;
  height: 180px;
  .crown {
    width: 20px;
    height: 20px;
  }
  ul {
    position: absolute;
    bottom: 0;
    z-index: 1;
    li {
      position: absolute;
      bottom: 0;
      .progress-num {
        position: absolute;
        top: -35px;
        font-size: 16px;
        color: #262626;
      }
      .progress-zhu {
        display: block;
        width: 15px;
        margin: 0 auto 27px;
        background: #d7d7d7;
      }
      .progress-zhu.reach {
        background: #2b82ff;
      }
      .progress-icon {
        position: absolute;
        bottom: 0px;
        left: -18px;
        width: 80px;
        color: #2b82ff;
      }
      .user-lv {
        position: absolute;
        top: -105px;
        left: -16px;
        text-align: center;
      }
    }
    .progress-lv1 {
      left: 100px;
      .progress-zhu {
        height: 0;
      }
    }
    .progress-lv2 {
      left: 300px;
      .progress-num {
        left: -10px;
      }
      .progress-zhu {
        height: 22px;
      }
    }
    .progress-lv3 {
      left: 500px;
      .progress-num {
        left: -12px;
      }
      .progress-zhu {
        height: 33px;
      }
    }
    .progress-lv4 {
      left: 700px;
      .progress-num {
        left: -15px;
      }
      .progress-zhu {
        height: 44px;
      }
    }
    .progress-lv5 {
      left: 900px;
      .progress-num {
        left: -18px;
      }
      .progress-zhu {
        height: 55px;
      }
    }
  }
  .progress-line {
    position: absolute;
    left: 30px;
    bottom: 25px;
    z-index: 0;
    width: 95%;
    border-bottom: 2px solid #9e9e9e;
    // width: 880px;
    // height: 1px;
    // background: rgba(214,179,129,.4);
  }
}
.privilege {
  display: flex;
  flex-wrap: wrap;
  li {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 20%;
    margin-top: 16px;
    .iconfont {
      font-size: 50px;
      color: #bfbfbf;
    }
    p {
      font-size: 14px;
      color: #8c8c8c;
      text-align: center;
      margin: 8px;
      span {
        color: #8c8c8c;
      }
    }
  }
  .vipItem {
    position: relative;
    .lock,
    .unlock {
      width: 70px;
      position: absolute;
      left: -13px;
      bottom: -5px;
      z-index: 1;
      text-align: center;
      // background-color: #f5f5f5;
      background-color: rgba(245, 245, 245, 0.8);
      border-radius: 4px;
      padding: 0 3px;

      span,
      i {
        font-size: 14px;
        color: #595959;
      }
    }
    .unlock {
      background-color: #ecf5ff;
      span,
      i {
        color: #409eff;
      }
    }
  }
}
.thirdRow .task {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  li {
    width: 48%;
    height: auto;
    border: 1px solid #dcdfe6;
    border-radius: 8px;
    padding: 16px 0;
    margin-bottom: 16px;
    .firstCol,
    .lastCol {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .iconfont {
      font-size: 50px;
      color: #2b82ff;
    }
    h3 {
      font-size: 16px;
      color: #2b82ff;
      margin: 0 0 10px;
    }
    p {
      font-size: 16px;
      color: #595959;
      margin: 0;
    }
  }
}
.disabledColor {
  color: #bfbfbf !important;
}
.abledColor {
  color: #2b82ff !important;
}
.icon-anchor-full {
  font-size: 14px;
}
.taskTips {
  font-size: 14px;
  color: #2b82ff;
}
.applyIcon {
  position: absolute;
  top: 0;
  right: -20px;
  font-size: 18px;
  color: #e6a23c;
  cursor: pointer;
}
.applied {
  color: #00f1a2;
}
@media screen and (max-width: 1600px) {
  .progress-lv1 {
    left: 100px !important;
  }
  .progress-lv2 {
    left: 250px !important;
  }
  .progress-lv3 {
    left: 400px !important;
  }
  .progress-lv4 {
    left: 550px !important;
  }
  .progress-lv5 {
    left: 700px !important;
  }
}
.toInvite {
  font-size: 14px;
  color: #2b82ff;
}
</style>
